<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
  >
    <v-toolbar
      flat
    >
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      />
      <v-spacer />
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2015-01"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-select
        v-model="select"
        :items="selects"
        item-text="text"
        item-value="value"
        label="Filter By"
      />
      <v-flex
        justify-end
      >
        <v-switch
          v-model="avatar"
          :label="(avatar) ? 'Show Avatar' : 'Hide Avatar'"
        />
      </v-flex>
    </v-toolbar>
    <div>
      <v-parallax
        dark
        height="1200"
        src="/img/background.jpg"
      >
        <v-container
          fluid
          grid-list-xs
        >
          <v-data-iterator
            :items="items"
            item-key="dealer"
            content-tag="v-layout"
            :expand="avatar"
            hide-actions
            justify-center
            row
            wrap
          >
            <template #item="props">
              <v-flex
                d-flex
                sm3
              >
                <v-badge
                  overlap
                  left
                  color="black"
                >
                  <template #badge>
                    {{ props.index + 1 }}
                  </template>
                  <v-card
                    height="150px"
                  >
                    <v-container grid-list-xs>
                      <v-layout
                        row
                        fill-height
                      >
                        <v-flex
                          v-if="avatar && props.item.avatar"
                          d-flex
                          xs4
                        >
                          <v-img
                            :src="props.item.avatar"
                            aspect-ratio="1"
                            contain
                          >
                            <template #placeholder>
                              <v-layout
                                fill-height
                                align-center
                                justify-center
                                ma-0
                                pa-0
                              >
                                <v-progress-circular
                                  v-show="props.item.loading"
                                  indeterminate
                                  color="grey lighten-5"
                                />
                              </v-layout>
                            </template>
                          </v-img>
                        </v-flex>
                        <v-flex
                          d-flex
                          :v-bind="avatar && props.item.avatar ? `xs4` : `xs8`"
                        >
                          <v-card
                            class="justify-center"
                            flat
                          >
                            <v-card-text>
                              <div class="font-weight-bold">
                                {{ props.item.dealer }}
                              </div>
                              <div class="subheader">
                                {{ props.item.name }}
                              </div>
                              <div class="subheader">
                                Total: {{ props.item.total }}
                              </div>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-badge>
              </v-flex>
            </template>
          </v-data-iterator>
          <v-flex
            pa-2
          >
            <v-card>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="items1"
                  :pagination.sync="pagination"
                  :rows-per-page-items="[10]"
                >
                  <template #items="props">
                    <tr @click="props.expanded = !props.expanded">
                      <td
                        class="text-xs-right"
                      >
                        <v-badge
                          left
                          color="black"
                        >
                          <template #badge>
                            {{ props.item.index }}
                          </template>
                        </v-badge>
                      </td>
                      <td
                        v-if="props.item.dealer"
                        class="text-xs-left"
                      >
                        {{ props.item.dealer }}
                      </td>
                      <td
                        v-else
                        class="text-xs-left"
                      >
                        -
                      </td>
                      <td
                        v-if="props.item.name"
                        class="text-xs-left"
                      >
                        {{ props.item.name }}
                      </td>
                      <td
                        v-else
                        class="text-xs-left"
                      >
                        -
                      </td>
                      <td
                        v-if="props.item.total"
                        class="text-xs-left"
                      >
                        {{ props.item.total }}
                      </td>
                      <td
                        v-else
                        class="text-xs-center"
                      >
                        -
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-container>
      </v-parallax>
      <v-alert
        :value="true"
        :type="alertType"
      >
        {{ alertMessage }}
      </v-alert>
    </div>
  </wide-panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Monthly Top Performance'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Top Performance', disabled: false, to: '/top_performance',
        },
        {
          text: title, disabled: true,
        },
      ],
      e2: 'Texas',
      avatar: false,
      alertType: 'info',
      alertMessage: 'The list name will be changed from time to time if there are demoted/terminated and suspended dealers in a current month.',
      items: [],
      items1: [],
      pagination: {},
      monthOf: null,
      month: null,
      select: {
        text: 'Activation',
        value: 1,
      },
      selects: [
        {
          text: 'Activation',
          value: 1,
        },
        {
          text: 'Dealer recruitment',
          value: 2,
        },
      ],
      headers: [
        {
          align: 'right',
          sortable: false,
          text: 'Rank',
          value: '',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealer ID',
          value: 'dealer',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Dealer Name',
          value: 'name',
        },
        {
          align: 'left',
          sortable: false,
          text: 'Total',
          value: 'total',
        },
      ],
      maxDate: '',
      menu: false,
      title,
    }
  },
  watch: {
    avatar: function (val) {
      if (!val) return
      this.items.forEach(item => {
        !item.avatar && this.getAvatar(item)
      })
    },
    monthOf: function (val) {
      this.getMonthlyTopPerformance(val)
    },
    select: function (val) {
      this.getMonthlyTopPerformance(val)
    },

  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
    // this.maxDate = this.day = now.plus({ days: -1 }).toFormat('yyyy-LL-dd')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2021, 5, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },

    getMonthlyTopPerformance: function () {
      this.loading = true
      const params = createGetParams({
        month: this.monthOf,
        select: this.select,
      })
      this.$rest.get('getTopPerformanceMonthly.php', params)
        .then(response => {
          this.items = response.data.slice(0, 10)
          this.items1 = response.data.slice(10, 50)
          if (this.avatar) {
            this.items.forEach(item => {
              !item.avatar && this.getAvatar(item)
            })
          }
        }).catch(e => {
          // ignore
        })
    },
    getAvatar: function (item) {
      item.loading = true
      this.$rest.get('getAvatar.php', createGetParams({ target: item.dealer }))
        .then(response => {
          item.avatar = response.data.avatar
          item.loading = false
        }).catch(e => {
          item.loading = false
          // ignore
        })
    },

  },
}
</script>
